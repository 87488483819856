import { render, staticRenderFns } from "./knowledge.vue?vue&type=template&id=04b25237&scoped=true&"
import script from "./knowledge.vue?vue&type=script&lang=js&"
export * from "./knowledge.vue?vue&type=script&lang=js&"
import style0 from "./knowledge.vue?vue&type=style&index=0&id=04b25237&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b25237",
  null
  
)

export default component.exports